import React, { useState } from 'react';
import { Container, Title, Text, Stack, Group, Box, Card, Loader, TextInput } from '@mantine/core';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount, useSignMessage } from 'wagmi';
import axios from 'axios';

const CustomEVMButton = () => {
    return (
        <ConnectButton.Custom>
            {({ account, chain, openAccountModal, openChainModal, openConnectModal, mounted }) => {
                const ready = mounted;
                const connected = ready && account && chain;

                return (
                    <div
                        {...(!ready && {
                            'aria-hidden': true,
                            'style': {
                                opacity: 0,
                                pointerEvents: 'none',
                                userSelect: 'none',
                            },
                        })}
                    >
                        {(() => {
                            if (!connected) {
                                return (
                                    <button
                                        onClick={openConnectModal}
                                        style={{
                                            backgroundColor: 'blue',
                                            color: 'white',
                                            height: '48px',
                                            padding: '0 24px',
                                            fontSize: '16px',
                                            fontWeight: '500',
                                            borderRadius: '4px',
                                            border: 'none',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        Connect EVM
                                    </button>
                                );
                            }

                            return (
                                <button
                                    onClick={openAccountModal}
                                    style={{
                                        backgroundColor: 'blue',
                                        color: 'white',
                                        height: '48px',
                                        padding: '0 24px',
                                        fontSize: '16px',
                                        fontWeight: '500',
                                        borderRadius: '4px',
                                        border: 'none',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {account.displayName}
                                </button>
                            );
                        })()}
                    </div>
                );
            }}
        </ConnectButton.Custom>
    );
};

const MigrationComponent = () => {
    const [ethereumMessage, setEthereumMessage] = useState('');
    const [status, setStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [solanaAddress, setSolanaAddress] = useState('');

    const { address: ethereumAddress } = useAccount();
    const { signMessageAsync: signEthereumMessage } = useSignMessage();

    const handleMigration = async () => {
        if (!ethereumAddress || !solanaAddress) {
            setStatus('Please connect your EVM wallet and enter your Solana address.');
            return;
        }

        setIsLoading(true);
        setStatus('');

        try {
            const ethereumSignature = await signEthereumMessage({ message: 'Migrate to Solana' });
            setEthereumMessage(ethereumSignature);

            const response = await axios({
                method: 'post',
                url: 'https://migrate.ghostswap.finance/verify-signature',
                data: {
                    ethereumSignature,
                    ethereumAddress,
                    solanaAddress,
                },
                timeout: 600000 // 10 minutes
            });

            if (response.status === 200) {
                setStatus('Migration successful!');
            } else {
                setStatus('Migration failed. Please try again.');
            }
        } catch (error) {
            if (error.code === 'ECONNABORTED') {
                setStatus('Request timed out after 10 minutes');
            } else {
                setStatus(`Error: ${error.message}`);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box
            style={{
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                backgroundColor: '#171717'
            }}
        >
            <Container size="lg">
                <Stack spacing="xl" align="center" justify="center" style={{ minHeight: '100vh' }}>
                    <Title order={1} style={{ color: 'white', fontSize: '3rem', marginBottom: '2rem', marginTop:'50px', textAlign: 'center' }}>
                        Migrate to Solana
                    </Title>

                    <Group position="center" spacing="xl" style={{ flexDirection: 'column' }}>
                        <CustomEVMButton />
                        <TextInput
                            placeholder="Enter your Solana address"
                            value={solanaAddress}
                            onChange={(event) => setSolanaAddress(event.currentTarget.value)}
                            style={{ width: '300px', marginTop: '1rem', justifyContent: 'center', textAlign: 'center' }}
                        />
                    </Group>

                    <button
                        onClick={handleMigration}
                        disabled={!ethereumAddress || !solanaAddress || isLoading}
                        style={{
                            marginTop: '2rem',
                            backgroundColor: '#212121',
                            color: 'white',
                            padding: '1rem 2rem',
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                            borderRadius: '8px',
                            border: 'none',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s ease',
                        }}
                    >
                        Start Migration
                    </button>

                    {isLoading ? (
                        <Loader color="cyan" size="xl" />
                    ) : status && (
                        <Text color="cyan" size="xl" weight={700} mt="xl" sx={{ textAlign: 'center' }}>
                            {status}
                        </Text>
                    )}

                    <Card
                        padding="lg"
                        radius="md"
                        withBorder
                        style={{
                            marginTop: '3rem',
                            backgroundColor: '#2C2C2C',
                            color: 'white',
                            maxWidth: '600px',
                            marginBottom:'50px'
                        }}
                    >
                        <Text>
                            Migration takes approximately 3 minutes per LittleGhost. You can leave the page at any time after migration begins.
                            Check your wallet 5 minutes after migration starts to confirm the transfer, if ghosts have not started to arrive, start migration
                            process again.<br/><br/>

                            Solana migration for LittleGhosts is now available. Simply connect your EVM wallet
                            containing LittleGhosts NFTs, enter the Solana destination address you wish to transfer
                            them to. Sign the Ethereum message, and your LittleGhosts NFTs on all chains will be
                            seamlessly transferred to your provided Solana address.<br/><br/>

                            Please note: LittleGhosts assets are now only valuable on the Solana chain. It's important
                            to remember that while these NFTs will not be burned on their original
                            chains, they will become non-valuable artifacts on those chains, preserved as historical
                            markers of this transition. We will open a new multi-chain bridge with Polygon & a new chain
                            ASAP.<br/><br/>

                            For EctoSkeletons, SoulEaters and other NFT assets: We will be enabling their migration in a
                            few days, after we confirm the bridge is working smoothly for LittleGhosts. Please check
                            back soon to migrate your other assets.<br/><br/>

                            Make sure to double-check your Solana address before initiating the migration. Once the
                            process
                            starts, it cannot be reversed.
                        </Text>
                    </Card>
                </Stack>
            </Container>
        </Box>
    );
};

export default MigrationComponent;