import React from 'react';
import { MantineProvider } from '@mantine/core';
import { WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import { ConnectionProvider } from '@solana/wallet-adapter-react';
import { clusterApiUrl } from '@solana/web3.js';
import { RainbowKitProvider, getDefaultWallets, darkTheme } from '@rainbow-me/rainbowkit';
import { WagmiProvider, createConfig, http } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { mainnet } from 'wagmi/chains';
import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  metaMaskWallet,
  rainbowWallet,
    trustWallet,
    coinbaseWallet,
    coreWallet,
  walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';

import MigrationComponent from './MigrationComponent';

// Import styles
import '@solana/wallet-adapter-react-ui/styles.css';
import '@rainbow-me/rainbowkit/styles.css';
import '@mantine/core/styles.css';

const App = () => {
  const solanaWallets = [new PhantomWalletAdapter()];

  const connectors = connectorsForWallets(
      [
        {
          groupName: 'Recommended',
          wallets: [rainbowWallet, metaMaskWallet,trustWallet,coreWallet,coinbaseWallet, walletConnectWallet],
        },
      ],
      {
        appName: 'Phantasma Migration',
        projectId: '719fce1aa38c50c0c23c2d315169ff00',
      }
  );

  const wagmiConfig = createConfig({
    chains: [mainnet],
    transports: {
      [mainnet.id]: http()
    },
    connectors
  });

  const queryClient = new QueryClient();

  return (
      <WagmiProvider config={wagmiConfig}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider theme={darkTheme()}>
            <MantineProvider theme={{
              colorScheme: 'dark',
              colors: {
                dark: ['#C1C2C5', '#A6A7AB', '#909296', '#5C5F66', '#373A40', '#2C2E33', '#25262B', '#1A1B1E', '#141517', '#101113'],
              },
              black: '#000000',
              white: '#ffffff',
              components: {
                Body: {
                  styles: {
                    root: {
                      backgroundColor: '#171717',
                      color: 'white',
                    },
                  },
                },
              },
            }}>
              <ConnectionProvider endpoint={clusterApiUrl('devnet')}>
                <WalletProvider wallets={solanaWallets} autoConnect>
                  <WalletModalProvider>
                    <MigrationComponent />
                  </WalletModalProvider>
                </WalletProvider>
              </ConnectionProvider>
            </MantineProvider>
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
  );
};

export default App;